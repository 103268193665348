<!-- eslint-disable func-names -->
<!-- eslint-disable func-names -->
<template>
  <div
    class="home-wrapper"
  >
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          :statistic="currentBalance + ' EUR'"
          statistic-title="CURRENT BALANCE"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="TrendingUpIcon"
          :statistic="monthlySales + ' EUR'"
          :statistic-title="monthlySalesLabel"
          color="success"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="ChevronsUpIcon"
          :statistic="biggestSale + ' EUR'"
          :statistic-title="biggestSaleLabel"
          color="info"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="CalendarIcon"
          :statistic="averageMonthly + ' EUR'"
          :statistic-title="averageMonthlyLabel"
        />
      </b-col>
      <b-col sm="12" />
      <b-col
        v-if="isPaymentRequestAllowed"
        md="12"
      >
        <b-card-actions
          title="Payment Form"
          action-collapse
        >
          <validation-observer ref="rules">
            <b-row class="d-flex justify-content-center">
              <b-col md="5">
                <b-form-group
                  label="Select payment method:"
                  label-for="payment-method"
                >
                  <b-form-select
                    id="payment-method"
                    v-model="paymentMethod"
                    :options="['Paypal' , 'Wire Transfer', 'Bitcoin']"
                  />
                </b-form-group>
                <div v-if="paymentMethod === 'Paypal'">
                  <validation-provider
                    #default="{ errors }"
                    name="paypal"
                    rules="required"
                  >
                    <b-form-group
                      label="Your paypal account"
                      label-for="paypal"
                    >
                      <b-form-input
                        id="paypal"
                        v-model="paypal"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div v-if="paymentMethod === 'Bitcoin'">
                  <validation-provider
                    #default="{ errors }"
                    name="bitcoin"
                    rules="required"
                  >
                    <b-form-group
                      label="Your bitcoin account"
                      label-for="bitcoin"
                    >
                      <b-form-input
                        id="bitcoin"
                        v-model="bitcoin"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div v-if="paymentMethod === 'Wire Transfer'">
                  <validation-provider
                    #default="{ errors }"
                    name="iban"
                    rules="required"
                  >
                    <b-form-group
                      label="IBAN:"
                      label-for="iban"
                    >
                      <b-form-input
                        id="iban"
                        v-model="iban"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="swift"
                    rules="required"
                  >
                    <b-form-group
                      label="SWIFT:"
                      label-for="swift"
                    >
                      <b-form-input
                        id="swift"
                        v-model="swift"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="bank"
                    rules="required"
                  >
                    <b-form-group
                      label="Bank Name:"
                      label-for="bank-name"
                    >
                      <b-form-input
                        id="bank-name"
                        v-model="bank"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="accname"
                    rules="required"
                  >
                    <b-form-group
                      label="Account Holder Name:"
                      label-for="account-name"
                    >
                      <b-form-input
                        id="account-name"
                        v-model="accname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="accadd"
                    rules="required"
                  >
                    <b-form-group
                      label="Account Holder Address:"
                      label-for="account-holder-address"
                    >
                      <b-form-input
                        id="account-holder-address"
                        v-model="accadd"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                </div>
              </b-col>
              <b-col md="5">
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required|min_value:100"
                >
                  <b-form-group
                    label="Amount Requested"
                    label-for="amount"
                    prepend="EUR"
                  >
                    <b-input-group
                      prepend="EUR"
                    >
                      <b-form-input
                        id="amount"
                        v-model="amount"
                        disabled
                      />
                      <b-button v-b-modal.revenue-modal>
                        See your revenue details.
                      </b-button>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <div style="margin-top:37px">
                  <p class="invoice-addr">
                    Traffic Manager Group S.R.L.
                    Ionel Teodoreanu 1, bloc 1, ap. 1
                    Brasov, Romania, 500165
                    Tax-ID/VAT: RO43058081</p>
                  <a
                    href="#"
                    @click="downloadInvoiceTemplate"
                  >Download Template</a>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="upload"
                  rules="required"
                >
                  <b-form-group
                    label="Upload Invoice Using The Template Above"
                    label-for="invoice-change"
                    class="mt-1"
                  >
                    <b-form-file
                      id="invoice-change"
                      v-model="upload"
                      accept=".xls"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <b-col sm="12">
                <hr class="mb-2">
              </b-col>
              <b-col
                md="6"
                class="text-center"
              />
              <b-col
                sm="12"
                class="text-center"
              >
                <b-button
                  variant="primary"
                  class="mb-2 mt-1"
                  :disabled="sendingPaymentAction"
                  @click="savePaymentRequest"
                >
                  <span v-if="sendingPaymentAction">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Sending...
                  </span>
                  <span v-else>Send Request</span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <b-card
          title="Request Payment List"
          style="positon:relative;"
        >
          <!-- table -->
          <vue-good-table
            v-if="clientPaymentList"
            ref="printMe"
            :columns="columns"
            :rows="clientPaymentList"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table', }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            class="mt-4"
          >
            <div
              slot="table-actions"
              style="border:0px solid #fff; display: flex; flex-direction: column; gap:5px;height: 160px;"
              class="heightActions"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                style="margin-right:5px"
                class="print-btn"
                @click="printList"
              >
                Print
              </b-button>
              <b-button
                variant="outline-primary"
                size="sm"
                style="margin-right:5px"
                class="pdf-btn"
                @click="downloadPDF"
              >
                <span v-if="makepdf">
                  <b-spinner
                    small
                    type="grow"
                  />
                  Creating...
                </span>
                <span v-else>PDF</span>
              </b-button>
              <b-button
                variant="outline-primary"
                size="sm"
                class="csv-btn"
                @click="downloadCSV"
              >
                CSV
              </b-button>
            </div>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: created_at -->
              <span v-if="props.column.field === 'created_at'">
                <b> {{ props.row.created_at }} </b>
              </span>

              <!-- Column: customAmount -->
              <span
                v-else-if="props.column.field === 'customAmount'"
                class="text-danger"
              >
                <b> {{ props.row.customAmount }} </b>
              </span>

              <!-- Column: customStatus -->
              <span
                v-else-if="props.column.field === 'customStatus'"
              >
                <b-badge :variant="statusVariant(props.row.customStatus)">
                  <b> {{ props.row.customStatus }} </b>
                </b-badge>
              </span>

              <!-- Column: action -->
              <center v-else-if="props.column.field === 'action'">
                <span>
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="viewDetails(props.row.id)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="12"
                    />
                    View Details
                  </b-button>
                </span>
              </center>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap mt-1">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="revenue-modal"
      cancel-variant="outline-secondary"
      size="xl"
      hide-footer
      centered
      title="Revenue Details"
      max-height="300px"
    >
      <AffiliateRevenueDetail
        :record-type="1"
        :show-action="false"
        :show-searchbox="false"
      />
    </b-modal>
    <b-modal
      id="revenue-bycomid-modal"
      cancel-variant="outline-secondary"
      size="xl"
      hide-footer
      centered
      :title="'Payment Details (ID: #'+commissionID+')'"
      max-height="300px"
    >
      <AffiliateRevenueDetail
        :record-type="3"
        :show-action="false"
        :show-searchbox="false"
        :com-i-d="commissionID"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormSelect, BFormGroup, BFormInput, BButton, BFormFile,
  BSpinner, BPagination, BInputGroup, BBadge,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@/libs/axios'

import Vue from 'vue'
import VueHtml2Canvas from 'vue-html2canvas'
import jsPDF from 'jspdf'
import AffiliateRevenueDetail from './affiliate/AffiliateRevenueDetail.vue'

Vue.use(VueHtml2Canvas)

export default {
  components: {
    BRow,
    BCol,
    BCard,
    StatisticCardVertical,
    BFormSelect,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormFile,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
    VueGoodTable,
    BPagination,
    BCardActions,
    BInputGroup,
    AffiliateRevenueDetail,
    BBadge,
  },
  data() {
    return {
      paymentMethod: 'Wire Transfer',
      bitcoin: '',
      paypal: '',
      iban: '',
      swift: '',
      bank: '',
      accname: '',
      accadd: '',
      upload: [],
      sendingPaymentAction: false,
      amount: 0,
      columns: [
        {
          label: 'Request ID',
          field: 'id',
        },
        {
          label: 'Request Date',
          field: 'created_at',
        },
        {
          label: 'Amount',
          field: 'customAmount',
        },
        {
          label: 'Payment Method',
          field: 'payment_method',
        },
        {
          label: 'Status',
          field: 'customStatus',
        },
        {
          label: 'Payment Date',
          field: 'customPaymentDate',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      pageLength: 20,
      makepdf: false,
      currentBalance: 0,
      monthlySales: 0,
      monthlySalesLabel: '',
      biggestSale: 0,
      biggestSaleLabel: '',
      averageMonthly: 0,
      averageMonthlyLabel: '',
      isPaymentRequestAllowed: true,
      commissionID: 0,
    }
  },
  computed: {
    clientPaymentList() {
      return this.$store.state.invoice.clientPaymentList
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Paid : 'light-success',
        Pending : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.fetchClientPaymentList()
  },
  mounted() {
    this.getAffiliateDetails()
  },
  methods: {

    fetchClientPaymentList() {
      this.$store.dispatch('invoice/fetchClientPaymentList')
        .catch(error => { console.log(error) })
    },

    downloadInvoiceTemplate() {
      axios({
        url: '/api/affiliates/download_invoice_template',
        method: 'GET',
        responseType: 'blob',
      }).then(res => {
        const filename = 'affiliate_template.xls'
        const link = document.createElement('a')
        const url = URL.createObjectURL(res.data)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        link.id = 'download_to_download'
        document.body.appendChild(link)
        document.getElementById(link.id).click()

        // remove to make sure a new csv file will be downloaded and not the previous one
        const element = document.getElementById(link.id)
        element.remove()
      })
    },
    async getAffiliateDetails() {
      // const response = await axios.get('/api/affiliates/affiliate/get_user_details')
      const response = await this.$store.dispatch('affiliate/fetchAffiliateData')
      if (response) {
        const data = response

        this.bitcion = data.bitcoin
        this.paypal = data.paypal_account
        this.iban = data.iban
        this.swift = data.swift
        this.bank = data.bank_name
        this.accname = data.acc_holder_name
        this.accadd = data.acc_holder_address
        this.amount = data.current_balance

        this.currentBalance = parseFloat(data.current_balance).toFixed(2)
        this.monthlySales = parseFloat(data.monthlySales.amount).toFixed(2)
        this.monthlySalesLabel = `MONTHLY SALES (${data.monthlySales.month} ${data.monthlySales.year})`
        this.biggestSale = parseFloat(data.biggestSale.amount).toFixed(2)
        this.biggestSaleLabel = `BIGGEST SALES (${data.biggestSale.date})`
        this.averageMonthly = parseFloat(data.monthlyAverage.average).toFixed(2)
        this.averageMonthlyLabel = `AVERAGE (${data.monthlyAverage.from} - ${data.monthlyAverage.to})`
        this.isPaymentRequestAllowed = data.isPaymentRequestAllowed
      }
    },

    savePaymentRequest() {
      this.sendingPaymentAction = true
      this.$refs.rules.validate().then(async success => { // eslint-disable-line
        if (success) {
          const formData = new FormData()
          formData.append('payment_select', this.paymentMethod)
          formData.append('bitcoin', typeof this.bitcoin === 'undefined' ? '' : this.bitcoin)
          formData.append('paypal', typeof this.paypal === 'undefined' ? '' : this.paypal)
          formData.append('iban', typeof this.iban === 'undefined' ? '' : this.iban)
          formData.append('swift', typeof this.swift === 'undefined' ? '' : this.swift)
          formData.append('bank', typeof this.bank === 'undefined' ? '' : this.bank)
          formData.append('accname', typeof this.accname === 'undefined' ? '' : this.accname)
          formData.append('accadd', typeof this.accadd === 'undefined' ? '' : this.accadd)
          formData.append('upload', typeof this.upload === 'undefined' ? '' : this.upload)
          formData.append('amount', typeof this.amount === 'undefined' ? '' : this.amount)

          const headers = { 'Content-Type': 'multipart/form-data' }
          try {
            const response = await axios.post('/api/affiliates/save_request_payment', formData, { headers })
            if (response.status === 200) {
              this.notify({
                text: 'Payment has been successfully verified and confirmed.',
                variant: 'success',
              })
              this.$router.go(0)
            }
          } catch (e) {
            this.notify({
              text: 'Something went wrong. Please contact us.',
              variant: 'danger',
            })
          }

          this.sendingPaymentAction = false
        }
        this.sendingPaymentAction = false
      })
    },

    printList() {
      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.printMe.$refs.table

      const header = require('@/assets/images/banner/invoiceheader.png')  // eslint-disable-line
      const head = document.getElementsByTagName('head') // eslint-disable-line

      const newWindowObj = window.open('', 'MyWindow')
      newWindowObj.document.write('<html>')
      newWindowObj.document.write('<body>')
      newWindowObj.document.write(`<img src=${header}/>`)
      newWindowObj.document.write(el.outerHTML)
      newWindowObj.document.write('<style>')
      newWindowObj.document.write('th,td {text-align: center;}')
      newWindowObj.document.write('th,td { border-bottom: 1px solid #000; padding: 3px;margin: 0px;}')
      newWindowObj.document.write('th,td { border-left: 1px solid #000; padding: 3px;margin: 0px;}')
      newWindowObj.document.write('th{ background: #e8e8e8; }')
      newWindowObj.document.write('table{ width: 80%; border-spacing: 0px; margin-left: auto;')
      newWindowObj.document.write('margin-right: auto;}')
      newWindowObj.document.write('tr > td:last-of-type {border-right: 1px solid #000; padding: 3px;margin: 0px;}')
      newWindowObj.document.write('tr > th:last-of-type {border-right: 1px solid #000; padding: 3px;margin: 0px;}')
      newWindowObj.document.write('th{border-top: 1px solid #000;}')
      newWindowObj.document.write('</style>')
      newWindowObj.document.write('</body></html>')
      newWindowObj.document.close()
      newWindowObj.print()
    },

    downloadCSV() {
      const header = [
        'Request Date',
        'Amount',
        'Payment Method',
        'Status',
        'Payment Date',
      ]

      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.printMe.$refs.table

      const rows = []
      Object.keys(el.rows).forEach(key => {
        if (key >= 1) {
          const row = []
          row.push(el.rows[key].cells[0].textContent.trim())
          row.push(el.rows[key].cells[1].textContent.trim())
          row.push(el.rows[key].cells[2].textContent.trim())
          row.push(el.rows[key].cells[3].textContent.trim())
          row.push(el.rows[key].cells[4].textContent.trim())
          rows.push(row)
        }
      })

      rows.unshift(header)
      const jsonObject = JSON.stringify(rows)
      const csv = this.convertToCSV(jsonObject)

      const exportedFilenmae = 'Payment List.csv'
      const blob = new Blob([`\ufeff${csv}`], { type: 'text/csv; charset=utf-8' })

      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', exportedFilenmae)
      link.style.visibility = 'hidden'
      link.id = 'downloadCsv'
      document.body.appendChild(link)
      document.getElementById('downloadCsv').click()

      // remove to make sure a new csv file will be downloaded and not the previous one
      const element = document.getElementById('downloadCsv')
      element.remove()
    },

    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''
      for (let i = 0; i < array.length; i++) { // eslint-disable-line
        let line = ''
        for (const index in array[i]) { // eslint-disable-line
          if (line !== '') line += ','
          line += array[i][index]
        }
        str += `${line}\r\n` // eslint-disable-line
      }
      return str
    },

    downloadPDF() {
      this.makepdf = true
      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */

      el = this.$refs.printMe.$refs.table
      const options = {
        type: 'dataURL',
      }
      const output = new this.$html2canvas(el, options)
      output.then(ContentCanvas => {
        // get the png invoice header
        /* eslint-disable global-require */
        const headerUrl = require('@/assets/images/banner/invoiceheader.png')
        /* eslint-enable global-require */

        this.getFileFromServer(headerUrl, invoiceDataUrl => {
          /* eslint-disable new-cap */
          const pdf = new jsPDF('p', 'px', 'a4')
          /* eslint-enable new-cap */

          pdf.addImage(invoiceDataUrl, 'png', 0, 0, 440, 100)

          const rowHeight = 20 * (el.rows.length)
          pdf.addImage(ContentCanvas, 'png', 5, 100, 1102 / 2.53, rowHeight)

          // const filename = Math.random().toString(36).substr(2, 5)
          const filename = 'RequestPaymentList'

          // create a new element
          const link = document.createElement('a')

          link.id = 'downloadPdf'
          link.href = pdf.output('datauristring')
          link.download = `${filename}.pdf`

          document.body.appendChild(link)
          document.getElementById('downloadPdf').click()

          // remove to make sure a new pdf will be downloaded and not the previous one
          const element = document.getElementById('downloadPdf')
          element.remove()
          this.makepdf = false
        })
      })
    },

    /*
    * Returns blob
    */
    getFileFromServer(url, callback) {
      const xhr = new XMLHttpRequest()
      // eslint-disable-next-line func-names
      xhr.onload = function () {
        const reader = new FileReader()
        // eslint-disable-next-line func-names
        reader.onloadend = function () {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    },

    viewDetails(id) {
      this.commissionID = id
      this.$bvModal.show('revenue-bycomid-modal')
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style scoped>
.home-wrapper div.col-sm-6.col-lg-3 a {
  color: #6e6b7b;
}

.pdf-btn {
    width:20%;
    position: absolute;
    top:30%;
    left: 7.5%;;
  }
  .csv-btn {
    width:20%;
    position: absolute;
    top:30%;
    left: 41%;
  }
  .print-btn {
    width:20%;
    position: absolute;
    top:30%;
    left: 75%;
  }

.aff-h6{
  max-width: 87rem;
  font-size: 16px;
  margin: 0 auto 28px;
  font-weight: bold;
  line-height: 21px;
  color: #8b8b8b;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 28px;
}

.invoice-addr {
  border: 1px solid #c9c9c9;
  padding: 14px;
  border-radius: 7px;
}

.vgt-input{
  width: 25% !important;
}
</style>
<style>
.mt-4 table tr th{font-size: 11px;}
.mt-4 table tr td{font-size: 12px;}
.csv-btn{
  width: 61px;
}

/* For phones- max width 500px */
@media only screen and (max-width: 550px) {
  .pdf-btn {
    width:200px !important;
    position: absolute;
    top:135px !important;
    left: 30% !important;
  }
  .csv-btn {
    width:200px !important;
    position: absolute;
    top:100px !important;
    left: 30% !important;
  }
  .print-btn {
    width:200px !important;
    position: absolute !important;
    top:65px !important;
    left: 30% !important;;
  }
  [dir] .vgt-input, [dir] .vgt-select {
    padding: 6px 12px;
    border-radius: 4px;
    background-image: none;
    background-color: #fff;
    border: 1px solid #d8d6de;
    width: 85% !important;
    position: absolute;
    top: 15px;
}
[dir=ltr] .vgt-global-search__input .input__icon {
    left: 0px;
    top:15px;
}
}
@media only screen and (max-width: 400px) {
  .pdf-btn {
    width:150px !important;
    position: absolute;
    top:135px !important;
    left: 30% !important;
  }
  .csv-btn {
    width:150px !important;
    position: absolute;
    top:100px !important;
    left: 30% !important;
  }
  .print-btn {
    width:150px !important;
    position: absolute !important;
    top:65px !important;
    left: 30% !important;
  }
  [dir] .vgt-input, [dir] .vgt-select {
    padding: 6px 12px;
    border-radius: 4px;
    background-image: none;
    background-color: #fff;
    border: 1px solid #d8d6de;
    width: 85% !important;
    position: absolute;
    top: 15px;
}
[dir=ltr] .vgt-global-search__input .input__icon {
    left: 0px;
    top:15px;
}
}

.dark-layout .vgt-global-search{
  border: 0px;
}
.dark-layout .vgt-input{
  border: 1px solid #3f4455;
  background: transparent;
  color: #6f7583;
}
.dark-layout .vgt-input:focus{
  border-color: #fb50a1;
}
</style>
